/* From Uiverse.io by Ankesh2002 */ 

.form-loader-container{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(2px); /* Adjust the blur intensity */
  background-color: rgba(255, 255, 255, 0.3); /* Optional: Semi-transparent color overlay */
 border-radius: 10px;
   height: 100%;

}
.form-loader {
  position: relative;
  width: 164px;
  height: 164px;
 
}

.form-loader::before , .form-loader::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #00bd26;
  left: 50%;
  top: 50%;
  animation: rotate 1s ease-in infinite;
}

.form-loader::after {
  width: 20px;
  height: 20px;
  background-color: #00bd26;
  animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite;
}

@keyframes moveY {
  0% , 100% {
    top: 10%
  }

  45% , 55% {
    top: 59%
  }

  60% {
    top: 40%
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -100%) rotate(0deg) scale(1 , 1)
  }

  25% {
    transform: translate(-50%, 0%) rotate(180deg) scale(1 , 1)
  }

  45% , 55% {
    transform: translate(-50%, 100%) rotate(180deg) scale(3 , 0.5)
  }

  60% {
    transform: translate(-50%, 100%) rotate(180deg) scale(1, 1)
  }

  75% {
    transform: translate(-50%, 0%) rotate(270deg) scale(1 , 1)
  }

  100% {
    transform: translate(-50%, -100%) rotate(360deg) scale(1 , 1)
  }
}
    