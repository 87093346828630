.button-common {
  padding: 5px 20px;
  margin: 3px;
  margin-right: 6px;
  /* font-weight: 700 !important; */
  /* max-width: 220px;*/
  width: max-content;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  transition: transform 0.3s;
  font-size: 16px;
  font-weight: 400;
  font-family: Lexend;
  cursor: pointer;
}
.button-common:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* transform: scale(1.02); */
}

.submit {
  background-color: #1ad079;
  border: none;
  color: rgb(0, 0, 0);
  /* width: 100%; */
}
.green-border {
  background-color: transparent;
  border: 2px solid #1ad079;
  color: rgb(255, 255, 255);
}
.services-green-border {
  /* background-color: #e7e7e7; */
  color: #0b0b1b;
  border: 2px solid #1ad079;
}
.services-green-border:hover {
  /* background-color: #e7e7e7; */
  color: #ffffff;
  background-color: #1ad079;
}
.contact-form-button {
  border: none;
  background-color: #e7e7e7;
  color: #717173;
}
.contact-form-button-purple {
  background-color: #3a37d6;
  color: rgb(255, 255, 255);
  border: none;
}
.contact-form-button-red {
  background-color: #d75142;
  color: rgb(255, 255, 255);
  border: none;
}
.contact-form-button-green {
  background-color: #149c5b;
  color: rgb(255, 255, 255);
  border: none;
}


.createContent-button{

  color: #0B0B1B;
  background-color: #1AD079;
  border: none;
}
.readmore-button {
  background-color: transparent;
  color: #149c5b;
  border: none;
  padding: 0px;
  /* transition: transform 0.3s; */
}

.readmore-button:hover {
  box-shadow: none;
  transform: none;
  transform: scale(1.02);
}

.backto-button {
  background-color: transparent;
  color: #717173;
  border: none;
  padding: 0px;
}

.backto-button:hover {
  box-shadow: none;
  transform: none;
  transform: scale(1.02);
}
.tag-button {
  border: 2px solid #e7e7e7;
  color: #717173;
}

.comment-share-button {
  border: none;
  color: #717173;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 20px !important;
  text-align: center;
  background-color: #e7e7e7;
}
.upvote-comments-button {
  font-size: 12px;
}

/* dashboard button */
.dashboard-button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: none;
}
.gray-button {
  color: #717173;
  background-color: #e7e7e7;
}
.gray-square-button {
  color: #373741;
  background-color: #e7e7e7;
  border-radius: 8px;
}
.green-button {
  color: white;
  background-color: #149c5b;
}
.dashboard-category-button {
  background-color: #3a37d61a;
  color: #3a37d6;
  width: 100px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 20px;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
  border: none;
}

.dashboard-status-button {
  background-color: #a9edcd;
  color: #10824c;
  width: 100px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 20px;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
  border: none;
}

.dashboard-gray-button {
  color: #0b0b1b;
  background-color: #e7e7e7;
  border: none;
}

@media (max-width: 768px) {

  .createContent-button{

    font-size: 12px;
    padding: 5px 9px !important;
  }

}

@media (max-width: 425px) {
  .comment-share-button {
    border: none;
    color: #717173;
    font-family: Lexend;
    font-size: 9px;
    font-weight: 400;
    padding: 2px 2px !important;
    text-align: center;
    background-color: #e7e7e7;
    width: 75px;
    margin-right: 1px !important;
  }
  .discussion-comment-button {
    font-size: 10px;
    font-weight: 400;
    padding: 2px 2px !important;
    width: 75px;
  }
}
