.create-post-container {
  width: 60%;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  padding: 20px;
  border-left: 1px solid #dcdcdc;
  /* border-right: 1px solid #dcdcdc; */
  height: 555px;
  margin-top: 20px;
}
.discussion-input-label {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;
}
.create-post-title {
  margin-bottom: 15px;
}

.create-post-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.input-title,
.input-content,
.input-tags {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: transparent;
}

.input-title {
  margin-bottom: 5px;
}

.input-content {
  height: 220px;
  resize: none;
}

.character-count {
  position: absolute;
  right: 10px;
  bottom: -20px;
  font-size: 12px;
  color: #373741;
}

.toolbar {
  display: flex;
  align-items: center;
  margin-top: -43px;
  padding-left: 10px;
  flex-wrap: wrap;
}

.toolbar button,
.font-select {
  border: none;
  background-color: transparent;
  /* margin-right: 8px; */
  font-size: 14px;
  cursor: pointer;
  color: #333;
}

.toolbar button:hover {
  color: #5b5fc7;
}

.toolbar .font-select {
  border-radius: 4px;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-btn,
.publish-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #ccc;
  margin-right: 10px;
  color: #333;
}

.cancel-btn:hover {
  background-color: #bbb;
}

.publish-btn {
  background-color: #5b5fc7;
  color: white;
}

.publish-btn:hover {
  background-color: #494db3;
}

.word-icons {
  width: 20px;
  height: auto;
}
.bold-btn.active {
  background-color: #dddde4;
  border-radius: 5px;
}
.Number-dot-aliment {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
.dot {
  width: 18px;
  height: auto;
}

@media (max-width: 1024px) {
  .toolbar {
    display: flex;
    align-items: center;
    margin-top: -53px;
    padding-left: 10px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .toolbar {
    display: flex;
    align-items: center;
    margin-top: -80px;
    padding-left: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
