.user-resume-details-des {
  color: #717173;
  max-width: 650px;
  width: 100%;
}

@media (max-width: 375px) {
  .user-resume-details-des {
    text-align: center;
  }
}
