.topic-heading {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;
}
.related-topics-container {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #16149c;
}
.topic-des-container > div:first-child {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #373741;
}
.topic-des-container > div:nth-child(2) {
  display: flex;
  align-items: flex-end;
}
.topic-des-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.create-post-button {
  width: 100%;
}
.recently-visited-postedby {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #3a37d6;
}
.upvote-comments-container {
  display: flex;
  gap: 8px;
  /* margin-top: -10px; */
}

.recently-visit-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  width: 30%;
}

.recently-visit-container > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recently-visit-container-first-div {
  border-bottom: 1px solid #e7e7e7;
}
.burning-topic-container {
  border-bottom: 1px solid #e7e7e7;
}
.upvote-comments-button {
  margin-top: 0px;
}
.recently-visit-container > div:first-child {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.host-container {
  display: flex;
  flex-direction: row !important;
}
.recent-dot {
  width: auto;
}
.recently-arrow-props {
  width: 24px;
  height: 24px;
}

@media (max-width: 1160px) {
  .recently-visit-container {
    width: 100%;
  }
  .create-post-button {
    width: 150px;
  }
  .recently-visit-container {
    padding: 0px;
  }
  .recently-visited-img-container {
    height: 180px;
  }
}
