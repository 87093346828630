.user-dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
}
.main-user-dashboard-container {
  max-width: 1100px;
  width: 100%;
  background-color: white;
  padding: 20px 20px 30px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.main-user-dashboard-container > div:first-child {
  display: flex;
  justify-content: space-between;
}
.user-jobreferrals-details-container h4 {
  font-weight: 400;
}
.user-jobreferrals-details-container p {
  color: #717173;
  size: 16px;
}
.main-user-dashboard-container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
}
.user-jobreferrals-details-container {
  max-width: 600px;
  /* width: 100%; */
}
.view-more-button {
  padding: 5px 20px;
  margin: 3px;
  margin-right: 6px;
  width: max-content;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  transition: transform 0.3s;
  font-size: 16px;
  font-weight: 400;
  font-family: Lexend;
  background-color: #149c5b;
  border: none;
  color: white;
}
.view-more-button:hover {
  /* transform: scale(1.05); */
  cursor: pointer;
}
.button-arrow-icon {
  width: 10px;
  height: auto;
  padding-left: 10px;
}
.user-application-details-container {
  display: flex;
  justify-content: space-between;
}
.user-application-details-container > div:first-child {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.user-application-details-heading {
  color: #149c5b;
  font-weight: 500;
}

.user-application-details-container > div:first-child > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.user-application-details-container > div:first-child > div > div:first-child {
  font-weight: 400;
}

.user-application-details-container > div:first-child > div > div:nth-child(2) {
  color: #717173;
  font-weight: 400;
}

@media (max-width: 630px) {
  .user-application-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .user-application-details-container > div:first-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .user-jobreferrals-details-container,
  .user-jobreferrals-details-container p {
    text-align: center;
  }
  .main-user-dashboard-container > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .view-more-button-container {
    display: flex;
    justify-content: center;
  }
  .user-application-details-heading {
    text-align: center;
  }
  .main-user-dashboard-container {
    gap: 30px;
  }
}

@media (max-width: 425px) {
  .user-application-details-container > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .user-application-details-container > div:first-child > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
}
