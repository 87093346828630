.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-drawer {
  background: white;
  padding: 0;
  border-radius: 10px;
  margin-left: 900px;
  /* margin-top: 484px; */
  margin-top: 275px;
  width: 520px;
  /* height: 776px; */
  height: 648px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.modal-drawer.maximized {
  /* width: 90%;
  height: 90%; */
  width: 90%;
  height: 70%;
}

.chat-minimized {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: black;
  color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
}

.chat-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-badge {
  background: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
  margin-left: auto;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: black;
  color: white;
  border-bottom: 1px solid #ddd;
}

.modal-header-left h4 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.modal-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-header-right button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  color: white;
}

.modal-content {
  flex: 1;
  /* overflow-y: auto; */
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-drawer {
    width: 90%;
    height: 90%;
  }

  .modal-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-header-right {
    margin-top: 10px;
    align-self: flex-end;
  }
}
