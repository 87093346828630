.main-user-dashboard-table-container {
  width: 100%;
}
.user-dashboard-table-container {
  position: relative;
  width: 100%;
}
.user-dashboard-table-heading-container {
  display: flex;
  justify-content: space-between;
}
.user-dashboard-table-heading-container > div:first-child {
  max-width: 680px;
  width: 100%;
}
.user-dashboard-table-main-heading {
  font-weight: 400;
}
.user-dashboard-table-des {
  color: #717173;
}

.user-dashboard-table-button {
  border: none;
  background-color: #149c5b;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  height: 50px;
  font-weight: 400;
}
.user-dashboard-table-button-icon {
  width: 20px;
  height: auto;
  padding-right: 10px;
}

@media (max-width: 768px) {
  .user-dashboard-table-heading-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
  .user-dashboard-table-main-heading {
    text-align: center;
  }
  .user-dashboard-table-des {
    text-align: center;
  }
}

@media (max-width: 650px) {
  .search-filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
@media (max-width: 425px) {
  .user-table-search {
    border-radius: 100px;
    max-width: 300px !important;
    width: 100% !important;
  }
}
