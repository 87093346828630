.dashbord-heading-container h3 {
  font-family: Lexend;
  color: #0b0b1b;
}
.dashbord-heading-container > div {
  font-family: Lexend;
  color: #717173;
  size: 16px;
  line-height: 24px;
}
