/* From Uiverse.io by vinodjangid07 */ 
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(2px); /* Adjust the blur intensity */
    background-color: rgba(255, 255, 255, 0.3); /* Optional: Semi-transparent color overlay */
   border-radius: 10px;
  }
  .loaderMiniContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 130px;
    height: fit-content;
  }
  .barContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    background-position: left;
  }
  .bar {
    width: 100%;
    height: 8px;
    background: linear-gradient(
      to right,
      var(--primary-color),
      var(--secondary-color),
      var(--primary-color)
    );
    background-size: 200% 100%;
    border-radius: 10px;
    animation: bar ease-in-out 3s infinite alternate-reverse;
  }
  @keyframes bar {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  .bar2 {
    width: 50%;
  }
  .svgIcon {
    position: absolute;
    left: -25px;
    margin-top: 18px;
    z-index: 2;
    width: 70%;
    animation: search ease-in-out 3s infinite alternate-reverse;
  }
  @keyframes search {
    0% {
      transform: translateX(0%) rotate(70deg);
    }
  
    100% {
      transform: translateX(100px) rotate(10deg);
    }
  }
  .svgIcon circle,
  line {
    stroke: var(--primary-color);
  }
  .svgIcon circle {
    fill: rgba(26, 208, 12, 0.238);
  }
  