.referral-form-sub-heading {
  color: #0b0b1b !important;
  font-weight: 400 !important;
  padding-bottom: 0px !important;
  padding-top: 30px;
}
.user-job-referral-form-request-container input {
  background-color: transparent;
}
.user-job-referral-form-request-container select {
  background-color: transparent;
}

.user-job-referral-form-request-container input:hover {
  border: 1px solid #1ad079;
}
