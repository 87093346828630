.main-contact-form-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 40px 0px;
}
.contact-details-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 40%;
}
.contact-form-sub-heading {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;
}
.contact-icon-details-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1.6px solid #e7e7e7;
  border-radius: 8px;
  gap: 20px;
  padding: 15px;
  background: #f8f8f8;
}
.contact-details-container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-icon-details-container > div {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #373741;
}
.contact-social-media-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 15px;
}

.contact-form-input {
  font-family: Lexend;
  width: 95%;
  border: 1.6px solid #e7e7e7;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  background-color: transparent;
  margin-bottom: 15px;
}
.contact-form-input::placeholder {
  color: #cacacb;
}
.contact-form-textarea {
  font-family: Lexend;
  width: 95%;
  border: 1.6px solid #e7e7e7;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-top: 10px;
  background-color: transparent;
  margin-bottom: 15px;
}
.contact-form-textarea::placeholder {
  color: #cacacb;
}
.contact-form-select {
  padding-top: 10px;
  color: #cacacb;
}
.main-contact-form-container > div:nth-child(2) {
  border-left: 1.6px solid #e7e7e7;
  padding-left: 30px;
}
.contact-form-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
}
.feedback-container {
  border-right: 1.6px solid #e7e7e7;
  padding-right: 20px;
}
.feedback-des-container {
  width: 25%;
  padding-top: 10px;
}

.feedback-des-container .feedback-des {
  color: #373741;
  size: 14px;
}
.feedback-hr {
  border: 0;
  border-top: 1.6px solid #e7e7e7;
  margin: 20px 0;
}

/* rating button */
.feedback-rating-container {
  display: flex;
  gap: 10px;
  margin: 5px 0;
}

.rating-button {
  width: 40px;
  height: 40px;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  color: #373741;
  font-size: 16px;
  font-weight: 400;
}

.rating-button:hover {
  background-color: #e0e0e0;
}

.rating-button.selected {
  background-color: #1ad079;
  color: #fff;
  border-color: #1ad079;
}

.rating-button:focus {
  outline: none;
}
.font-family {
  font-family: Lexend;
}

@media (max-width: 768px) {
  .main-contact-form-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 0px;
  }
  .main-contact-form-container > div:nth-child(2) {
    border-left: none;
    padding-left: 0px;
    border-right: none;
  }
  .contact-details-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
  .main-contact-form-container > div:nth-child(2) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .feedback-des-container {
    width: 100%;
  }
}
