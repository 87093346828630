.about-people-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-people-aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-people-details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-around;
}

.people-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-people-img-container {
  display: flex;
  flex-direction: column;
  border-radius: 100%;
  background-color: #e7e7e7;
}
.about-people-img {
  height: 200px;
  width: 200px;
  border-radius: 100%;
  padding: 15px;
  object-fit: cover;
  object-position: top center;
}

.people-details-container > div:nth-child(2) {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #0b0b1b;
}
.people-details-container > div:nth-child(3) {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
