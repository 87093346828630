.user-job-referral-form-request-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  color: black;
  border-radius: 10px;
  position: relative;
  
}
.user-job-referral-form-request {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.user-job-referral-form-request-container h4 {
  color: #0b0b1b;
  font-weight: 400;
}
.user-job-referral-form-request-container h5 {
  color: #149c5b;
  font-weight: 500;
  padding-bottom: 20px;
}

.user-job-referral-form-request-container p {
  color: #717173;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-job-referral-form-input-container {
  width: 48%;
}
.user-job-referral-form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-job-referral-form-group input select {
  width: 100%;
  height: 30px;
  border-radius: 8px;
  padding-left: 10px;
}

.user-job-referral-form-group select {
  width: 100%;
  height: 38px;
  border-radius: 8px;
  padding-left: 10px;
}

.user-job-referral-form-group input::placeholder {
  color: #cacacb;
}
.sub-user-job-referral-form-group {
  display: flex;
  justify-content: space-between;
}

.required {
  color: red;
}
.upload-des {
  font-size: 12px;
  color: #717173;
}
.user-request-form {
  width: 100% !important;
  height: 200px !important;
  border: 1.6px solid #cacacb;
  padding: 0px !important;
}
.pay-cancel-button {
  background-color: #e7e7e7;
  color: #717173;
}
.user-request-form-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-input .placeholder-option {
  color: #cacacb;
}
/* Additional fix for Firefox */
.form-input:invalid {
  color: #cacacb;
}
.validation-error {
  color: red !important;
  font-size: 12px;
}

@media (max-width: 530px) {
  .sub-user-job-referral-form-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }
  .user-job-referral-form-input-container {
    width: 100%;
  }
  .user-request-form-actions {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 320px) {
  .user-dashboard-table-button {
    padding: 5px 10px;
  }
}
