.dashboard-comment-share-button-container > div {
  display: flex;
  flex-direction: row;
}

.dashboard-comment-share-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
