.chat-tab {
  padding: 7px 5px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
}

.chat-tab.active {
  color: #4caf50;
  border-bottom: 2px solid #4caf50;
}

/* Base styles */
.jri-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.jri-navbar {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.jri-tabs {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.jri-tab {
  padding: 8px 0;
  border: none;
  background: none;
  cursor: pointer;
  color: #6b7280;
  white-space: nowrap;
}

.jri-tab-active {
  color: #10b981;
  border-bottom: 2px solid #10b981;
}

.jri-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Sidebar styles */
.jri-sidebar {
  width: 300px;
  border-right: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  background: white;
}

.jri-search-container {
  padding: 16px;
  position: relative;
}

.jri-search-input {
  width: 100%;
  padding: 8px 8px 8px 32px;
  border: none;
  background-color: #f3f4f6;
  border-radius: 6px;
}

.jri-search-icon {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.jri-jobs-list {
  overflow-y: auto;
}

.jri-job-item {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
  gap: 22px;
}

.jri-avatar-group {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.jri-avatar {
  /* width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.jri-avatar-overlap {
  position: absolute;
  left: 19px;
  top: 10px;
}

.jri-job-info {
  flex: 1;
  margin-left: 8px;
  min-width: 0;
}

.jri-job-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jri-job-company {
  color: #6b7280;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jri-job-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  gap: 8px;
}

.jri-job-time {
  font-size: 12px;
  color: #6b7280;
}

.jri-badge {
  background-color: #ef4444;
  color: white;
  padding: 2px 6px;
  border-radius: 9999px;
  font-size: 12px;
  /* margin-top: 4px; */
}

/* Chat container styles */
.jri-chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.jri-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  flex-wrap: wrap;
  gap: 16px;
}

.jri-chat-users {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.jri-user {
  display: flex;
  align-items: center;
}

.jri-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
}

.jri-user-info {
  margin-left: 8px;
}

.jri-user-name {
  font-weight: 500;
  display: block;
}

.jri-user-role {
  color: #6b7280;
  font-size: 14px;
}

.jri-advisor-role {
  font-size: 14px;
}

.jri-chat-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.jri-status-badge {
  background-color: #8b5cf6;
  color: white;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 14px;
}

.jri-view-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #10b981;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.jri-chat-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.jri-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.jri-message-user {
  justify-content: flex-start;
}

.jri-message-referrer {
  justify-content: flex-end;
}

.jri-message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
}

.jri-message-content {
  max-width: 60%;
  margin: 0 8px;
}

.jri-message-user .jri-message-content {
  background-color: #e8f5e9;
  border-radius: 6px;
  padding: 12px;
}

.jri-message-referrer .jri-message-content {
  background-color: #e3f2fd;
  border-radius: 6px;
  padding: 12px;
}

.jri-message-time {
  display: block;
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
}

/* Responsive Breakpoints */

/* Large screens (desktop) */
@media screen and (min-width: 1024px) {
  .jri-sidebar {
    width: 300px;
  }

  .jri-chat-header {
    flex-wrap: nowrap;
  }
}

/* Medium screens (tablet) */
@media screen and (max-width: 1023px) {
  .jri-sidebar {
    width: 250px;
  }

  .jri-chat-users {
    gap: 16px;
  }

  .jri-message-content {
    max-width: 70%;
  }
}

/* Small screens (mobile) */
@media screen and (max-width: 767px) {
  .jri-content {
    flex-direction: column;
  }

  .jri-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
    max-height: 40vh;
  }

  .jri-chat-header {
    padding: 12px;
  }

  .jri-chat-users {
    flex-direction: column;
    gap: 8px;
  }

  .jri-chat-actions {
    width: 100%;
    justify-content: space-between;
  }

  .jri-message-content {
    max-width: 80%;
  }
}

/* Extra small screens */
@media screen and (max-width: 479px) {
  .jri-navbar {
    padding: 8px;
  }

  .jri-tabs {
    gap: 12px;
  }

  .jri-chat-header {
    padding: 8px;
  }

  .jri-user-avatar,
  .jri-message-avatar {
    width: 28px;
    height: 28px;
  }

  .jri-message-content {
    max-width: 90%;
  }

  .jri-view-btn {
    padding: 6px 12px;
    font-size: 12px;
  }
}
