.referrer-information-main-container {
  background-color: #f6f6f6;
}

.referrer-information-main-container .setting-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}
