.sidebar-container {
  position: relative;
}

.sidebar-logo-text {
  color: white;
  margin-top: 6px;
  font-size: var(--font-24-px);
}

.sidebar-container-section {
  background-color: var(--text-body);
  min-height: 100vh;
  height: 100%;
  background: var(--primary-black);
  z-index: 1;
  width: 304px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-container nav {
  padding-top: 35px;
  display: flex;
  justify-content: center;
  padding-left: 32px;
  padding-bottom: 145px;
}

.admin-side-bar-user-profile {
  display: none;
}

.sidebar-container ul {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.sidebar-container li {
  color: #cacacb;
  height: 54px;
  width: 222px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-weight: 400;
  font-size: var(--font-size-h6);
}

.sidebar-active {
  background-color: #373741;
  color: #ffffff !important;
}

.sidebar-container span {
  padding-right: 16px;
  font-size: var(--font-20-px);
}

.sidebar-container a {
  all: unset;
}

.sidebar-container li:hover {
  background-color: #37374159;
  color: #ffffff !important;
}

.sidebar-logo-container {
  text-align: center;
  padding: 20px;
  font-weight: 700;
  font-size: var(--font-19-px);
  padding-top: 60px;
}

.sidebar-logo-container img {
  height: 60px;
  width: 225px;
}

.sidebar-container .menu {
  display: none;
  z-index: 2;
  top: 0px;
  left: 0;
}
.account-container {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
/* .account-container a {
  all: unset;
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 240px;
  flex-direction: row;
  background-color: var(--bg-black);
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
} */
/* .account-container {
  position: relative;
  height: 100%; 
} */

.account-container a {
  all: unset;
  position: absolute;
  bottom: 10px; /* Positioning it 10px from the bottom */
  display: flex;
  width: 240px;
  flex-direction: row;
  background-color: var(--bg-black);
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
}

.account-container img {
  height: 50px;
  width: 55px;
  border-radius: 50%;
}
.account-container p:first-child {
  font-size: var(--font-size-h6);
  font-weight: 500;
  line-height: 0px;
}
.account-container p {
  font-size: var(--font-size-h6);
  font-weight: 300;
  color: var(--white-color);
}
.sidebar-child {
  margin-left: 42px;
}

.logout-btn-continer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-button-admin {
  max-width: 100px;
  width: 100%;
  padding: 10px;
  background-color: #149c5b;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
}

.logout-button-admin:hover {
  background-color: #0e5834;
}

@media only screen and (max-width: 1279px) {
  .account-container a {
    display: none;
  }
  .admin-side-bar-user-profile {
    display: block;
    position: absolute;
    right: 27px;
    top: 8px;
  }
}
@media only screen and (max-width: 1280px) {
  .sidebar-admin-container {
    position: fixed;
    background: white;
    height: 55px;
    width: 100%;
    z-index: 100;
  }
  .sidebar-container .menu {
    display: flex;
    top: 0;
    right: 0;
    padding: 20px 0px 0px 31px;
  }

  .sidebar-container-section {
    display: block;
    position: absolute;
  }
  .sidebar-container {
    position: absolute;
  }
}
@media only screen and (max-width: 600px) {
  .admin-side-bar-user-profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
  .admin-side-bar-user-profile {
    display: block;
    position: absolute;
    right: 27px;
    top: 7px;
  }
  .sidebar-container .menu {
    display: flex;
    top: 3px;
    left: 9px;
    z-index: 3;
    padding: 15px 10px;
    position: fixed;
    /* justify-content: flex-end; */
    /* color: white; */
    padding-right: 17px;
  }

  .sidebar-container-section {
    display: block;
    position: fixed;
    width: 100vw;
  }
}
.admin-side-bar-user-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar-admin-container {
}
