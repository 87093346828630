.personal-information {
  width: 28%;
}
.main-user-setting-dashboard {
  background-color: #f6f6f6;
}
.user-setting-page {
  border: none;
}
.user-setting-hr {
  border: none;
  border-bottom: 2px solid #eeeeee;
  margin: 0px 0;
  /* padding-left: 43px; */
  margin-left: 30px;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .personal-information {
    width: 100%;
  }
}
