.main-success-services-container {
  display: flex;
  flex-direction: row;
}
.success-services-heading {
  font-family: Tektur;
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  color: #0b0b1b;
}
.success-services-heading-des {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #282834;
}
.success-services-container > p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
.success-services-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.success-services-image-container > img {
  width: 50%;
  height: auto;
}
.servies-path-img {
  /* max-width: 600px; */
  height: auto;
  width: 54%;
}
/* .servies-path-img-container{
  display: flex;
flex-direction: column;
} */

@media (max-width: 768px) {
  .main-success-services-container {
    display: flex;
    flex-direction: column;
  }
  .servies-path-img {
    height: auto;
    width: 100%;
  }
  .success-services-heading {
    text-align: center;
  }
  .success-services-heading-des {
    text-align: center;
  }
  .success-services-container > p {
    text-align: center;
  }

  .success-services-image-container > img {
    width: 48%;
  }
}
