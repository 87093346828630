.sub-navbar {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.sub-navbar-item {
  padding: 10px 15px;
  cursor: pointer;
  border: none;

  color: #373741;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.sub-navbar-item:hover {
  background-color: #f0f0f0;
}

.sub-navbar-item.active {
  color: #10824c;
  font-weight: 600;
}

.sub-navbar-item.active::after {
  content: ""; /* Necessary to render the pseudo-element */
  position: absolute;
  bottom: 0; /* Align to the bottom of the tab */
  left: 0;
  width: 100%; /* Span the entire width of the tab */
  height: 3px; /* Height of the green underline */
  background-color: #10824c; /* Green underline color */
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.sub-navbar-item .count {
  margin-left: 8px; /* Add space between label and count */
  color: #373741; /* Style the count */
  font-size: 12px; /* Make the count slightly smaller */
}

.sub-navbar-item.active .count {
  background-color: #a9edcd; /* Add background color to the count */
  padding: 2px 6px; /* Add some padding around the count */
  border-radius: 4px; /* Optional: make the background rounded */
  color: #10824c; /* Change count color to match active text */
}
