.simple-layout-main-container {
  min-height: calc(100vh - var(--nav-bar-size));
  overflow: hidden;
}

.simple-layout-main-container section {
  padding: 20px 70px 20px 70px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

@media all and (max-width: 1300px) {
  .simple-layout-main-container section {
    padding: 60px 70px 90px 70px;
  }
}
@media all and (max-width: 1142px) {
  .simple-layout-main-container {
    min-height: calc(100vh - 368px - 65px);
  }
}
@media all and (max-width: 1050px) {
  .simple-layout-main-container section {
    padding: 40px 70px 25px 70px;
  }
}

@media all and (max-width: 550px) {
  .simple-layout-main-container section {
    padding: 18px 20px 20px 20px;
  }
}

@media all and (min-width: 1700px) {
  .simple-layout-main-container section {
    padding: 50px 150px 20px 150px;
  }
}

@media all and (min-width: 1800px) {
  .simple-layout-main-container section {
    max-width: var(--max-width-container);
    min-width: 1700px;
    width: 100%;
    padding: 60px 200px 50px 200px;
    z-index: 2;
    box-sizing: border-box;
    /* background-color: rgb(194, 194, 194); */
  }

  .simple-layout-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
}
