.blog-post-table-container {
  padding: 0px;
}
.container {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tabs {
  display: flex;
  /* margin-bottom: 20px; */
}

.tab {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
}

.tab.active {
  color: #4caf50;
  border-bottom: 2px solid #4caf50;
}

.tab-count {
  background-color: #eee;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 5px;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 10px 30px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.buttons {
  display: flex;
}

.control-button {
  margin-left: 10px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.content-table {
  width: 100%;
  border-collapse: collapse;
}

.content-table th,
.content-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.content-table th {
  background-color: #f8f8f8;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.status.completed {
  background-color: #e8f5e9;
  color: #4caf50;
}

.status.pending {
  background-color: #ede7f6;
  color: #673ab7;
}

.dashboard-table-heading {
  display: flex;
  background-color: #f8f8f8;
  height: 90px;
  align-items: center;
  padding-left: 20px;
  border-radius: 8px 8px 0px 0px;
  background-color: #f8f8f8;
}
