.user-layouts {
  width: 97%;
}

.user-full-detailes {
  padding-left: 20px;
}

.user-information-heading {
  padding-bottom: 5px;
  font-weight: 500;
}
.pending-status-badge {
  background-color: #9747ff;
  color: white;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 14px;
}
.sub-state-row {
  display: flex;
}

@media (max-width: 768px) {
  .user-information-heading {
    text-align: center;
  }

  .title-place {
    margin: 20px;
  }
}
