.ai-review-container {
  display: flex;
  flex-direction: column;
}

.status-select {
  margin-right: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 8px;
}

.status-select:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

/* Option styles */
.status-option {
  padding: 8px;
}

.status-option:first-child {
  color: #9ca3af;
  background-color: #f9fafb;
}

.status-option.completed {
  color: #059669;
}

.status-option.rejected {
  color: #dc2626;
}

.status-option.pending {
  color: #2563eb;
}

/* Selected state styles */
.status-select[value="completed"] {
  background-color: #059669;
  color: white;
  border-color: #059669;
}

.status-select[value="rejected"] {
  background-color: #dc2626;
  color: white;
  border-color: #dc2626;
}

.status-select[value="pending"] {
  background-color: #2563eb;
  color: white;
  border-color: #2563eb;
}

/* Hover styles */
.status-select:not([value=""]):hover {
  opacity: 0.9;
}

.feedback-ai-input-container {
  width: 100%;
  height: 200px;
  background-color: transparent;
}
.cv-consultant-review-child {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 630px) {
  .jra-heading-container {
    display: flex;
    flex-direction: column;
  }
  .jra-save-button {
    margin-left: 13px;
  }
  .cv-consultant-review-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
