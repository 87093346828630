.updated-withus-container {
  padding: 50px 0px 50px 0px;
}
.updated-withus-container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: #0b0b1b;
  border-radius: 20px;
  justify-content: space-evenly;
}
.updated-with-heading {
  font-family: Tektur;
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 96px;
  text-align: left;
}
.heading-different-color {
  color: #1ad079;
}
.updated-with-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 650px;
  padding: 20px 0px 20px 40px;
}
.updated-with-details-container p {
  color: #cacacb;
  font-size: 15px;
  text-align: left;
}
.updated-with-right-image {
  height: 400px;
  width: auto;
  padding-left: 20px;
}
.community-right-image {
  opacity: 0.2;
  height: 400px;
  width: auto;
  padding-right: 20px;
}
.email-input {
  width: 100%;
  max-width: 400px;
  height: 60px;
  padding: 0 10px;
  border: 2px solid #373741;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50px;
  padding-left: 30px;
}
.input-button-container {
  display: flex;
  flex-direction: row;
}
.input-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
}
.community-button {
  position: absolute;
  right: 16px;
  top: 35px;
  height: 30px;
  color: white;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 50px;
}
.community-button:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

/* 
  .community-button:hover {
    background-color: #0056b3;
  } */
@media (max-width: 1024px) {
  .updated-with-right-image {
    display: none;
  }
  .updated-with-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .updated-with-right-image {
    display: none;
  }
  .updated-with-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding: 10px;
  }
  .updated-with-details-container p {
    text-align: center;
  }
  .updated-with-heading {
    line-height: normal;
    text-align: center;
  }
  .updated-withus-container > div {
    padding: 30px 5px;
    width: 97%;
  }
  .our-services-container-heading {
    text-align: center;
  }
}
@media (max-width: 320px) {
  .email-input {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 925px) {
  .updated-with-details-container {
    padding: 20px !important;
  }
}
