.main-container {
  /* width: calc(100vw - 360px); */
  /* width: 100%; */
  padding: 30px 33px 40px 312px;
  background: #ffffff;
  height: calc(100vh - 70px);
}

@media only screen and (max-width: 1279px) {
  .main-container {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 69px 33px 40px 33px;
  }
}

@media only screen and (max-width: 1100px) {
  .main-container {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 62px 33px 40px 33px;
  }
}

@media only screen and (max-width: 600px) {
  .main-container {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 72px 15px 0px 15px;
  }
}
