/* ImageUploadGallery.css */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.image-container {
  position: relative;
  width: 160px;
  height: 160px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.remove-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border: 2px dashed #a9edcd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-label:hover {
  background-color: #f3f4f6;
}

.upload-icon {
  font-size: 32px;
  margin-bottom: 8px;
}

.upload-label span:last-child {
  font-size: 14px;
}

.file-input {
  display: none;
}
