.jra-heading-container {
  display: flex;
  justify-content: space-between;
}
.jra-save-button {
  display: flex;
  align-items: center;
  background-color: #149c5b;
  font-size: 14px;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 30px;
  padding: 0px 10px;
  margin-right: 35px;
  width: max-content;
}
.save-icon {
  width: 15px;
  padding-right: 10px;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.meeting-form {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 40px;
}

.career-form-group {
  margin-bottom: 15px;
  max-width: 230px;
  width: 100%;
  height: 48px;
}
.career-form-input-group {
  margin-bottom: 15px;
  max-width: 300px;
  width: 100%;
  height: 48px;
}

.meeting-input,
.meeting-date,
.meeting-time {
  width: 100%;
  padding: 0px 10px !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  height: 40px;
}

.jra-timeline-career-hr {
  border: none;
  height: 6px;
  background-color: #149c5b;
  margin: 20px 0;
  max-width: 120px;
  width: 100%;
  opacity: 0.8;
  border-radius: 50px;
}
