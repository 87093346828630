.success-stories-container {
  /* padding-top: 50px; */
  /* border-radius: 20px; */
  /* box-shadow: 0px 0px 40px 0px rgba(11, 11, 27, 0.08); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.success-stories-container > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  padding: 50px;
  gap: 20px;
}
.stories-description-container {
  width: 100%;
  background-image: url("/src/assets/images/SuccuessStoryRightBackground.svg"),
    url("/src/assets/images/SuccuessStoryLeftBackground.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 30%;
  background-position:
    top right,
    bottom left;
  background-repeat: no-repeat, no-repeat;
  background-size: 30%, 30%;
  min-height: 320px;
}

.stories-description-container > div:first-child {
  font-family: Tektur;
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  color: #0b0b1b;
}
.stories-description-container > div:nth-child(2) {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1ad079;
}
.stories-description-container > div:nth-child(3) {
  font-family: Lexend;
  /* max-width: 450px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #E7E7E7;
  padding-right: 20px;
}
.profile-images-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding-top: 30px;
  gap: 20px;
}
.first-member-image-container {
  width: 290px;
  height: 290px;
  overflow: hidden;
  border-radius: 100%;
}
.current-member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.members-image-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 100%;
}
.other-member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.member-privious-next-button {
  width: 24px;
  height: 24px;

  padding: 15px;
  background-color: white;
  color: #cacacb;
  border-radius: 100%;
  font-size: 10px;
  transition: all 0.3s ease;
  position: relative;
  top: 36px;
  transform: translate(-50%, -50%);
}

.member-privious-next-button:hover {
  transform: translate(-50%, -50%) scale(1.5); /* Increase size by 1.5 times */
  background-color: #1ad079;
  color: #0b0b1b;
  font-size: 20px;
}

.main-success-stories-container > div:nth-child(3) > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 40px; */
}
.member-next-button-container {
  display: flex;
  justify-content: flex-end;
  margin-right: -38px;
}
.member-privious-button-container {
  display: flex;
  justify-content: flex-start;
  margin-left: -3px;
}

.profile-images-container > div:first-child {
  padding: 4px;
  background: linear-gradient(to right, #f4f4f4 50%, #cacacb 50%);
  border-radius: 100%;
}

.profile-images-container > div:first-child > div {
  padding: 25px;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.profile-images-container > div:nth-child(2) {
  padding: 2px;
  background: linear-gradient(to right, #f4f4f4 50%, #cacacb 50%);
  border-radius: 100%;
}

.profile-images-container > div:nth-child(2) > div {
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.profile-images-container > div:nth-child(3) {
  padding: 2px;
  background: linear-gradient(to left, #f4f4f4 50%, #cacacb 50%);
  border-radius: 100%;
}

.profile-images-container > div:nth-child(3) > div {
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 100%;
}
.circles-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.circles-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 5px;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  border: 2px solid #cacacb; /* Adding border with color */
  background-color: #fff; /* Optional: Set a white background for contrast */
}

.active-circle {
  background-color: #1ad079;
  border: 2px solid #1ad079;
}
.main-success-stories-container > div:nth-child(3) > div {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 40px 0px rgba(11, 11, 27, 0.08);
  border-radius: 20px;
  padding-bottom: 30px;
  margin-top: 40px;
  background-color: #10824C;
}
.main-our-success-stories-container {
  padding: 10px;
}

@media (max-width: 1285px) {
  .members-image-container {
    width: 50px;
    height: 50px;
  }
  .first-member-image-container {
    width: 200px;
    height: 200px;
  }
  .profile-images-container > div:nth-child(3) > div {
    padding: 8px;
  }
  .profile-images-container > div:nth-child(2) > div {
    padding: 10px;
  }
}

@media (max-width: 943px) {
  .success-stories-container > div:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    padding: 50px;
    gap: 20px;
    width: 80%;
  }
  .member-next-button-container {
    display: none;
  }
  .member-privious-button-container {
    display: none;
  }
}
@media (max-width: 768px) {
  .profile-images-container {
    gap: 10px;
  }
  .profile-images-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 590px) {
  .member-privious-next-button {
    padding: 8px;
  }

  /* .member-privious-next-button:hover {
    transform: translate(-50%, -50%) scale(1.2);
    font-size: 12px;
  }
  .member-next-button-container {
    display: flex;
    justify-content: flex-end;
    margin-right: -27px;
  } */
  .profile-images-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
  }
}
