/* Color Variables */
:root {
  --primary-color: #1ad079;
  --secondary-color: #03dac6;
  --background-color: #ffffff;
  --primary-black: #0b0b1b;
  --bg-black: #282834;
  --text-color: #000000;
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --error-color: #f44336;
  --white-color: #ffffff;
  --title-path: #16149c;
  --gray-s100: #f8f8f8;
  /* Typography Variables */
  --font-family: "Lexend", sans-serif;
  --font-size-base: 16px;
  --font-size-h1: 64px;
  --font-size-h2: 48px;
  --font-size-h3: 36px;
  --font-size-h4: 24px;
  --font-size-h5: 20px;
  --font-size-h6: 16px;
  --font-size-body1: 16px;
  --font-size-body2: 0.875rem;
  --font-size-caption: 0.75rem;

  /* Spacing Variables */
  /* --spacing-unit: 8px; */

  /* Border Radius */
  --border-radius: 4px;

  /* Shadow */
  --box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  --max-width-container: 1440px;

  /* Breakpoints */
  --breakpoint-xs: 480px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 1024px;
  --breakpoint-lg: 1200px;
}

/* Global Styles */
body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: bold;
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: bold;
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: bold;
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: bold;
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: bold;
}

h6 {
  font-size: var(--font-size-h6);
  font-weight: bold;
}

p {
  font-size: var(--font-size-body1);
}

small {
  font-size: var(--font-size-caption);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Button Styles */
/* button {
    background-color: var(--primary-color);
    color: #fff;
    padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3);
    border: none;
    border-radius: var(--border-radius);
    font-size: var(--font-size-body1);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    transition: background-color 0.3s ease;
  }
   */
/* button:hover {
    background-color: var(--secondary-color);
  }
   */
/* Form Elements */
input,
textarea,
select {
  font-family: var(--font-family);
  font-size: var(--font-size-body1);
  padding: calc(var(--spacing-unit) * 2);
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  /* box-shadow: var(--box-shadow); */
  margin-bottom: var(--spacing-unit);
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-color);
  outline: none;
}

label {
  font-size: var(--font-size-body2);
  margin-bottom: calc(var(--spacing-unit) * 0.5);
  display: block;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: calc(var(--spacing-unit) * 2);
}

/* Card */
.card {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: calc(var(--spacing-unit) * 3);
  margin-bottom: calc(var(--spacing-unit) * 4);
}

/* Utility Classes */
.mt-1 {
  margin-top: calc(var(--spacing-unit) * 1);
}
.mt-2 {
  margin-top: calc(var(--spacing-unit) * 2);
}
.mt-3 {
  margin-top: calc(var(--spacing-unit) * 3);
}
.mt-4 {
  margin-top: calc(var(--spacing-unit) * 4);
}

.mb-1 {
  margin-bottom: calc(var(--spacing-unit) * 1);
}
.mb-2 {
  margin-bottom: calc(var(--spacing-unit) * 2);
}
.mb-3 {
  margin-bottom: calc(var(--spacing-unit) * 3);
}
.mb-4 {
  margin-bottom: calc(var(--spacing-unit) * 4);
}

.p-1 {
  padding: calc(var(--spacing-unit) * 1);
}
.p-2 {
  padding: calc(var(--spacing-unit) * 2);
}
.p-3 {
  padding: calc(var(--spacing-unit) * 3);
}
.p-4 {
  padding: calc(var(--spacing-unit) * 4);
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

/* Flexbox Utilities */
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}

/* Media Queries */
@media (max-width: 1200px) {
  h1 {
    font-size: 64px;
  }
  h2 {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
    text-align: center;
  }
  h2 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 30px;
  }
}
