.main-landing-referrer-header-container {
  display: flex;
  background-image: url("/src/assets/images/ReferrerLayout/Images/referrer-header-img.svg");
  width: auto;
  aspect-ratio: 16 / 9;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center 0px;
}
.landing-referrer-header-container {
  display: flex;
  flex-direction: row;
  /* background-color: #0b0b1b; */
  height: auto;
  align-items: center;
  padding-left: 30px;
  padding-right: 50px;
  padding-bottom: 30px;
  max-width: 1330px;
}
.landing-referral-header-image {
  width: 50%;
  margin-top: 0px;
}
.landing-referral-header-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.referral-header-button-icon {
  width: 30px;
  height: auto;
}
.referral-header-button {
  width: 350px;
  height: 80px;
}
.referral-header-button {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #1ad079;
  border-radius: 8px;
}
.header-button-colour {
  background-color: #f8f8f80d;
  color: white;
}

/* @media (max-width: 1230px) {
  .main-landing-referrer-header-container {
    background-position: center 0px;
  }
  .landing-referral-header-image {
    width: 50%;
    margin-top: 0px;
  }
} */

@media (max-width: 840px) {
  .referral-header-button {
    width: 250px;
    height: 60px;
  }
}

@media (max-width: 768px) {
  .main-landing-referrer-header-container {
    display: flex;
    background-image: none;
    background-color: #0b0b1b;
    background-position: center 0px;
    height: auto;
    aspect-ratio: auto;
  }
  .landing-referrer-header-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .landing-referral-header-image {
    width: 100%;
    margin-top: 0px;
  }
}
@media (max-width: 320px) {
  .referral-header-button {
    width: 210px;
    height: 50px;
  }
  .referral-header-button-icon {
    width: 20px;
    height: auto;
  }
}
