.path-container {
  display: flex;
  /* margin-top: 52px; */
  /* margin-left: 48px; */
  flex-direction: row;
  justify-content: space-between;
  color: var(--title-path);
  cursor: pointer;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.path-container div {
  font-size: var(--font-size-base);
  color: var(--title-path);
  padding-left: 4px;
}
.Notification-icon-place {
  /* max-width: 1000px; */
  width: 78%;
  display: flex;
  justify-content: flex-end;
}
.Notification-icon {
  width: 44px;
  height: 44px;
  padding-right: 43px;
}
.file-path-place {
  display: flex;
}

@media (max-width: 425px) {
  .path-container {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  /* .Notification-icon {
    margin-right: -76px;
    margin-top: -29px;
} */
}

@media (max-width: 768px) {
  .Notification-icon {
    margin-top: -10px;
    padding-right: 0px;
  }
}
