.user-image-place {
  display: flex;
}

.userImage-class {
  width: 60px;
  height: auto;

  padding-right: 20px;
}

.user-layouts-02 {
  width: 97%;
  display: flex;
  flex-direction: column;
  height: 548px;
  /* background-color: aqua; */
  justify-content: space-evenly;
}
.sub-date {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}

@media (max-width: 768px) {
  .user-image-place {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .userImage-class {
    padding-right: 0px;
  }

  .user-layouts-02 {
    height: 100%;
    margin: 0 auto;
  }
}
