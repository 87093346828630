.dashboard-table-heading-learninghub {
    display: flex;
    background-color: #f8f8f8;
    height: 90px;
    align-items: center;
    padding-left: 20px;
    border-radius: 8px 8px 0px 0px;
    background-color: #f8f8f8;
    justify-content: space-between;
}
.create-button-place{
    padding-right: 15px;

}