.job-buttons-container {
  display: flex;
  gap: 10px;
}

.job-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  font-family: Lexend;
}

.job-referrals {
  background-color: #f0f0f0;
  color: #0b0b1b;
}

.career-advice {
  background-color: #28a745;
  color: white;
}

.resume-review {
  background-color: #f0f0f0;
  color: #333;
}

.notification {
  position: absolute;
  background-color: #dc3545;
  color: white;
  border-radius: 100px;
  padding: 3px;
  font-size: 12px;
  margin-left: 5px;
}

/* sub-navbar */
.dashboard-subnav-button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #f4f4f4;
  height: 50px;
  align-items: center;
  /* width: 500px; */
  width: 334px;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 40px;
  border: 1px solid #e7e7e7;
}
.subnav-button-item {
  cursor: pointer;
  color: #0b0b1b;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding: 0px 10px;
}

.subnav-button-item.active {
  color: white;
  background-color: #149c5b;
  padding: 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
