/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 30px;
}

/* Buttons Styles */
.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #e7e7e7;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  color: #717173;
}

/* Active Page Button */
.pagination button.active {
  background-color: #1ad079;
  color: #0b0b1b;
}

/* Hover Effect for Non-Active Buttons */
.pagination button:hover:not(.active) {
  background-color: #ddd;
}

/* Disabled Button Styles */
.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Arrow Button Styles */
.pagination-arrow-button {
  margin: 0 5px;
  padding: 10px;
}

/* Icon Styles */
.previous-next-button-icon {
  font-size: 10px;
  color: #717173;
}

.previous-next-button-img {
  width: 15px;
  height: auto;
}

/* Media Query for Screens smaller than 1024px */
@media screen and (max-width: 1024px) {
  .pagination {
    gap: 20px;
  }

  .pagination button {
    padding: 8px 12px;
    font-size: 14px;
    margin: 8px;
  }

  .pagination-arrow-button {
    padding: 8px;
  }

  .previous-next-button-img {
    width: 14px;
  }
}

/* Media Query for Screens smaller than 768px */
@media screen and (max-width: 768px) {
  .pagination {
    gap: 15px;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .pagination button {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pagination-arrow-button {
    padding: 8px;
  }

  .previous-next-button-img {
    width: 12px;
  }
}
