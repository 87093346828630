.update-post-form {
  max-width: 800px;
  padding: 20px;
  font-family: Lexend;
  width: 100%;
}

.update-post-form-first-child {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1.6px solid #ccc;
  border-radius: 8px;
}
input[type="text"]::placeholder,
textarea::placeholder {
  color: #cacacb;
  font-size: 16px;
  font-weight: 400;
  font-family: "Lexend";
}

textarea {
  height: 100px;
  resize: vertical;
}

.category-buttons {
  display: flex;
  gap: 10px;
}

.category-buttons button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.category-buttons button.active {
  background-color: #4caf50;
  color: white;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.tag {
  background-color: #f8f8f8;
  padding: 8px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #373741;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid #e7e7e7;
}

.tag button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 20px;
  color: #373741;
  font-weight: 400;
}
.image-upload-container {
  display: flex;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  width: 95px;
  justify-content: center;
}

.upload-button {
  display: flex;
  background-color: transparent;
  border: none;
  /* padding: 10px 20px; */
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-button > div {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.form-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.publish-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.draft-button,
.cancel-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.upload-button-img {
}

.text-area-content-lenth-container {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
