/* Chat Container */
.chat-app-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 490px;
  height: 500px;

}

.chat-app-message-container {
  display: flex;
  flex-direction: column;
}

.jri-chat-header {
  /* position: relative; */
  position: sticky;
  top: -21px;
  background-color: #ffffff;
  z-index: 100;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.jri-chat-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  flex-direction: column-reverse;
  gap: 8px !important;
}

/* Messages Container */
.chat-app-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100% - 300px);
  margin-bottom: 67px;
}

/* Message Styles */
.chat-app-message {
  display: flex;
  margin-bottom: 16px;
  gap: 10px;
  align-items: flex-start;
}

.chat-avatar-container {
  width: 48px;
  height: 48px;
}

.chat-app-message.sent {
  justify-content: flex-end;
}

.chat-app-message.received {
  justify-content: flex-end;
  /* justify-content: flex-start; */
}

.jri-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-app-message-content {
  max-width: 70%;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
}
.chat-app-message-content img{
  max-width: 100%;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
}

.chat-app-message.received .chat-app-message-content {
  background-color: #e0f2fe;
  margin-left: 8px;
}

.chat-app-message.sent .chat-app-message-content {
  background-color: #dcfce7;
  margin-right: 8px;
}

.chat-app-message-time {
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
  display: block;
}

/* Input Area */
.chat-app-input-container {
  padding: 16px;
  background-color: #ffffff;
  border-top: 1px solid #e5e7eb;
  position: relative;

  position: sticky;
  bottom: -14px;
}

.chat-app-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-app-text-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px 0 0 6px;
  font-size: 14px;
  outline: none;
}

.chat-app-text-input:focus {
  outline: none;
  border-color: #10b981;
}

.chat-app-attachment-btn {
  padding: 12px;
  background-color: #ffffff;
  /* border: 1px solid #e5e7eb; */
  /* border-left: none; */
  cursor: pointer;
}

.chat-app-send-btn {
  padding: 12px 16px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}

.file-preview-container {
  position: absolute;
  bottom: 70px;
  left: 16px;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: calc(100% - 32px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.file-preview-container a {
  color: #007bff;
  text-decoration: none;
  margin-right: 10px;
}

.file-preview-container button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

/* Utility Classes */
.chat-app-icon {
  width: 20px;
  height: 20px;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .chat-app-header {
    padding: 12px;
  }

  .chat-app-message-content {
    max-width: 85%;
  }

  .chat-app-buttons {
    flex-direction: column;
  }
}
