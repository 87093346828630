.discussion-fourm-filter-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  padding: 10px;
}
.discussion-forum-search {
  position: relative;
  width: 100% !important;
  padding-top: 20px;
}
.discussion-forum-search-input {
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border: 1.6px solid #cacacb;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  padding-left: 55px;
  border-radius: 8px;
}
.discussion-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 33px;
  border-radius: 20px;
  background-color: #1ad079;
  font-family: Lexend;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;
  border: none;
  padding: 2px 13px;
}
.discussion-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.discussion-button-icon {
  width: 18px;
  height: auto;
}
.discussion-button-icon-fire {
  width: 13px;
  height: auto;
}

@media (min-width: 1160px) and (max-width: 1383px) {
  .discussion-button {
    gap: 6px;
    height: 40px;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
  }
}
