.main-journey-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.main-journey-container > div {
  width: 60%;
}
.main-journey-container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  width: 60%;
}
.des-paragraph {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
  padding-bottom: 20px;
}
.journey-img {
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .main-journey-container {
    display: flex;
    flex-direction: column;
  }
  .main-journey-container > div {
    width: 100%;
  }
  .main-journey-container > div:nth-child(2) {
    width: 100%;
  }
  .des-paragraph {
    text-align: center;
  }
}
